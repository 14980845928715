<template>
  <a-layout>
    <section class="search__container">
      <common-search :fields="fields" @search="onSearch"></common-search>
    </section>
    <section class="table__container">
      <a-table
        :columns="tblColumns"
        :data-source="tblData"
        :loading="loading"
        :pagination="pagination"
        rowKey="orderSn"
        @change="onPage"
      >
        <template slot="orderStatusValue" slot-scope="text">
          <span
            v-if="text === '待发货' || text === '部分发货'"
            class="status--orange"
          >
            {{ text }}
          </span>
          <span v-else>
            {{ text }}
          </span>
        </template>
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a @click="onDetail(record.orderSn)">查看</a>
            <a
              v-if="
                record.orderStatusValue === '待发货' ||
                  record.orderStatusValue === '部分发货'
              "
              @click="onDeliver(record.orderSn, record.orderType)"
            >
              发货
            </a>
          </a-space>
        </template>
      </a-table>
    </section>
  </a-layout>
</template>

<script>
import { loadArea } from "@/services/DealerService";
import {
  fetchCorporationInfoByKeyword,
  fetchToDeliverOrderList
} from "@/services/OrderManageService";
import { tsFormat } from "@/components/DateUtils";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn",
    width: "15%"
  },
  {
    title: "买方机构",
    dataIndex: "purchaserCorporationName",
    width: "15%"
  },
  {
    title: "收货地区",
    dataIndex: "consigneeAreaName",
    width: "15%"
  },
  {
    title: "商品数量",
    dataIndex: "totalQuantity",
    width: "5%"
  },
  {
    title: "订单总额（元）",
    dataIndex: "totalFactoryPrice",
    width: "6%"
  },
  {
    title: "订单类型",
    dataIndex: "orderTypeValue",
    width: "8%"
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "16%"
  },
  {
    title: "发货状态",
    dataIndex: "orderStatusValue",
    scopedSlots: { customRender: "orderStatusValue" },
    width: "10%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];

export default {
  name: "ToDeliverOrderList",
  data() {
    return {
      fields: [
        {
          label: "订单编号",
          key: "orderSn",
          component: "j-input"
        },
        {
          label: "发货状态",
          key: "deliverStatus",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "待发货",
              value: "not_consignment"
            },
            {
              label: "部分发货",
              value: "portion_consignment"
            },
            {
              label: "已发货",
              value: "finish_consignment"
            }
          ]
        },
        {
          label: "创建时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "订单类型",
          key: "orderType",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "即时订单",
              value: "INSTANT"
            },
            {
              label: "远期订单",
              value: "FORWARD"
            },
            {
              label: "租赁订单",
              value: "LEASE"
            }
          ]
        },
        {
          label: "买方机构",
          key: "purchaserDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        },
        {
          label: "收货地区",
          key: "consigneeProvinceCode,consigneeCityCode,consigneeCountyCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.loadAreaData();
          }
        }
      ],
      form: {},
      areaData: [],
      tblColumns,
      tblData: [],
      loading: false,
      pagination: {
        showQuickJumper: true
      },
      corporationList: [],
      tsFormat
    };
  },
  activated() {
    if (this.$store.state.hasChange) {
      this.pagination.current = 1;
      this.loadList();
    }
    this.$store.state.hasChange = false;
  },
  mounted() {
    this.loadList();
  },
  methods: {
    // 加载行政区数据
    async loadAreaData() {
      const resp = await loadArea();
      if (resp.data.code === "SUCCESS") {
        this.areaData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return this.areaData;
    },
    // 搜索下单机构
    async onSearchCorporation(value) {
      let options = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.distributorId,
            label: `${item.corporationName}(${item.distributorTypeValue})`
          });
        });
      }
      return options;
    },
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        orderSn: this.form.orderSn || "",
        purchaserDistributorId: this.form.purchaserDistributorId || "",
        startTime: this.form.startTime ? this.form.startTime : "",
        endTime: this.form.endTime ? this.form.endTime : "",
        orderCategory: "PURCHASE",
        orderType: this.form.orderType || "",
        factoryId: localStorage.getItem("distributorId"),
        consigneeProvinceCode: this.form.consigneeProvinceCode
          ? this.form.consigneeProvinceCode
          : "",
        consigneeCityCode: this.form.consigneeCityCode
          ? this.form.consigneeCityCode
          : "",
        consigneeCountyCode: this.form.consigneeCountyCode
          ? this.form.consigneeCountyCode
          : "",
        deliverStatus: this.form.deliverStatus || "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      fetchToDeliverOrderList(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.tblData = data.records;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 查看
    onDetail(orderSn) {
      this.$router.push({
        name: "ToDeliverOrderDetail",
        params: { orderSn: orderSn }
      });
    },
    // 发货
    onDeliver(orderSn, orderType) {
      this.$router.push({
        name: "PublicDeliver",
        params: { orderSn: orderSn, type: orderType }
      });
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item .ant-form-item-label {
  min-width: 72px;
}

/deep/ .ant-form-item {
  margin-right: 40px;
}
</style>
